<!-- 园区管理-园区配置 -->

<template>
  <div class="secnf main-cnt">
    <div class="title">园区列表</div>
    <div class="content">
      <common-table ref="scenicTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
        :apiName="ScenicApi.semanageList" :filters="filters" :columns="tableColumns" @areaClick="areaClick"
        @albumClick="albumClick" @statusChange="statusChange" @edit="scenicEdit" @guide="showGuide"
        @check="openCheckDialog">
      </common-table>
    </div>

    <!-- 新增/编辑 园区弹框 -->
    <w-dialog ref="scenicArea" class="scenic-area" :title="isAreaEdit ? '编辑园区' : '新增园区'" width="55%" btnWidth="140px"
      top="8vh" :confirmText="isAreaEdit ? '确认编辑' : '确认新增'" @wConfirm="scenicEditConfirm">
      <el-form class="area-form" ref="areaFormObj" :model="areaForm" :rules="areaRules" labelPosition="top">
        <el-form-item label="园区名称" prop="name">
          <el-input v-model="areaForm.name" placeholder="请输入园区名称"></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="通行时间" prop="transitTime">
          <el-time-picker v-model="areaForm.transitTime" is-range range-separator="至" start-placeholder="开始时间"
            end-placeholder="结束时间">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="联系电话" prop="s_hot_line">
          <el-input type="number" v-model="areaForm.s_hot_line" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="园区地址" prop="addr">
          <el-input v-model="areaForm.addr" placeholder="请输入园区地址"></el-input>
        </el-form-item>
        <el-form-item label="园区坐标" prop="coord">
          <el-input v-model="areaForm.coord" placeholder="请输入园区坐标"></el-input>
        </el-form-item>
        <el-form-item class="block" label="园区简介" prop="intro">
          <div ref="scenicEditorElem"></div>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 园区入口 弹框 -->
    <w-dialog ref="areaEntrance" class="entrance" title="入口列表" width="60%" btnWidth="140px" top="10vh"
      :hideFooter="true">
      <div class="name">园区名称</div>
      <common-table ref="areaTable" tableHeight="calc(100vh - 360px)" :ischeck="false" :ispaging="true"
        :apiName="ScenicApi.sentranceList" :columns="areaTableColumns" :extraParame="{ s_id: currentRow.s_id }"
        @edit="entranceEdit" @statusChange="entranceStatusChange">
      </common-table>
    </w-dialog>

    <!-- 园区入口 编辑弹框 -->
    <w-dialog ref="aentEdit" class="aent-edit" title="编辑园区入口" width="45%" btnWidth="140px" top="20vh" confirmText="确认编辑"
      @wConfirm="aentEditConfirm">
      <el-form class="aent-form" ref="aentFormObj" :model="aentForm" :rules="aentRules" labelPosition="top">
        <el-form-item label="所属园区" prop="scenic">
          <el-input v-model="aentForm.scenic" disabled placeholder="请选择所属园区"></el-input>
        </el-form-item>
        <el-form-item label="入口名称" prop="name">
          <el-input v-model="aentForm.name" placeholder="请输入入口名称"></el-input>
        </el-form-item>
        <el-form-item label="通行时间" prop="transitTime">
          <el-time-picker v-model="aentForm.transitTime" is-range range-separator="至" start-placeholder="开始时间"
            end-placeholder="结束时间">
          </el-time-picker>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 景点入口 弹框 -->
    <w-dialog ref="spotEntrance" class="entrance" title="景点列表" width="60%" btnWidth="140px" top="10vh"
      :hideFooter="true">
      <div class="name">景点名称</div>
      <common-table ref="spotTable" tableHeight="calc(100vh - 360px)" :ischeck="false" :ispaging="true"
        :apiName="ScenicApi.spotEntranceList" :extraParame="{ s_id: currentRow.s_id }" :columns="spotTableColumns"
        @statusChange="spotStatusChange" @edit="spotEdit" @check="openSpotCheckDialog">
      </common-table>
    </w-dialog>

    <!-- 新增/编辑 景点弹框 -->
    <w-dialog ref="scenicSpot" class="scenic-spot" :title="isSpotEdit ? '编辑景点' : '新增景点'" width="42%" btnWidth="140px"
      top="16vh" :confirmText="isSpotEdit ? '确认编辑' : '确认新增'" @wConfirm="spotEditConfirm">
      <el-form class="spot-form" ref="spotFormObj" :model="spotForm" :rules="spotRules" labelPosition="top">
        <el-form-item label="景点名称" prop="name">
          <el-input v-model="spotForm.name" placeholder="请输入景点名称"></el-input>
        </el-form-item>
        <el-form-item label="通行时间" prop="transitTime">
          <el-time-picker v-model="spotForm.transitTime" is-range range-separator="至" start-placeholder="开始时间"
            end-placeholder="结束时间">
          </el-time-picker>
        </el-form-item>
        <el-form-item class="block" label="景点简介" prop="introduce">
          <div ref="spotEditorElem"></div>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 游玩指引 弹框 -->
    <w-dialog ref="guideDialog" class="guide-dialog" title="游玩指引" width="60%" btnWidth="140px" top="10vh"
      confirmText="确认编辑" @wConfirm="guideConfirm">
      <div style="margin-bottom: 15px;color: var(--text-color);">游玩指引内容</div>
      <div ref="guideEditorElem"></div>
    </w-dialog>
    <!--图册 弹框 -->
    <w-dialog ref="photoDialog" class="photo-dialog" title="园区图册" width="40%" btnWidth="140px" top="10vh"
      v-loading.fullscreen.lock="fullLoading" @wConfirm="photoDialog.close()" :hideFooter="true">
      <div class="name">园区图片</div>
      <img-upload uploadTitle="园区图片" :limit="99" suggestText="建议尺寸750*592px，不超过2MB"
        :fileList="photoAlbum && photoAlbum.length ? photoAlbum : []" @uploadFile="uploadFile" @deleteFile="deleteFile">
      </img-upload>
      <!-- 上传封面 -->
      <div class="name">上传封面</div>
      <img-upload :limit="1" :fileList="coverPhoto && coverPhoto.length ? coverPhoto : []"
        suggestText="建议尺寸750*258px，不超过2MB" @uploadFile="uploadFile($event, true)" @deleteFile="deleteFile"></img-upload>
    </w-dialog>
    <CheckDialog ref="checkDialog"></CheckDialog>
    <CheckDialog ref="spotCheckDialog"></CheckDialog>
  </div>
</template>
<script setup>
  import { ref, reactive, onMounted, nextTick, watch, computed } from "vue";
  import { ElMessage } from "element-plus";
  import imgUpload from "@/components/img-upload/img-upload.vue";
  import { BasicApi, ScenicApi } from "@/plugins/api.js";
  import * as dayjs from "dayjs";
  import * as Qiniu from "qiniu-js";
  // 引入富文本编辑器
  import Editor from "wangeditor";
  import { useStore } from "vuex";
  import CheckDialog from "@/views/hotel/components/CheckDialog.vue";
  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const qiniuData = computed(() => store.state.Config.qiniuData);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 筛选条件列表 */
  const filters = ref([
    {
      filterType: "search",
      name: "keywords",
      value: "",
      placeholder: "输入关键字搜索",
    },
  ]);
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "s_id",
      label: "园区ID",
      minWidth: 50,
      color: "--text-color",
    },
    {
      prop: "s_name",
      label: "园区名称",
      minWidth: 200,
      color: "--text-color",
      showTooltip: true,
    },
    {
      type: "block",
      prop: "entrance_count",
      label: "园区入口",
      minWidth: 80,
      active: "areaClick",
      token: "n_kzlAg8FlzBRahXoNnYjSJs2xtLgp",
    },
    {
      prop: "s_place",
      label: "地址信息",
      minWidth: 150,
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "s_business_hours",
      label: "营业时间",
      minWidth: 120,
      color: "--text-third-color",
    },
    {
      type: "switch",
      prop: "s_status",
      label: "园区状态",
      minWidth: 120,
      token: "n_kKdBSO3OovqrYFs5w32DiuLPknWk",
    },
    {
      type: "block",
      prop: "files_count",
      label: "图册",
      minWidth: 80,
      active: "albumClick",
      token: "n_Vn7DFkn74vFb18mIzsFXrmpWH4yX",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 300,
      bottons: [
        {
          name: "编辑",
          action: "edit",
          token: "n_UX0sxjm0bCWeesY9xnzpXfjO0Gli",
          className: "theme-font-btn",
        },
        {
          name: "游玩指引",
          action: "guide",
          token: "n_hLODLOKkWIlgMNh1ehJfk8dYq1wd",
          className: "black-font-btn",
        },
        {
          name: "退款管理员",
          action: "check",
          token: "n_KNFPbmAJsGJWpWc9PM70TT7MbGGL",
          className: "theme-font-btn",
        },
      ],
    },
  ]);
  /** 园区表格数据 */
  const scenicTable = ref(null);
  /** 当前操作行 */
  const currentRow = ref(null);
  /** 园区入口点击处理 */
  const areaClick = (row) => {
    currentRow.value = row;
    areaEntrance.value.show();
    nextTick(() => {
      // 获取园区入口列表数据
      areaTable.value.tableLoad();
    });
  };
  /** 状态改变处理 */
  const statusChange = (row) => {
    let data = {
      s_id: row.s_id,
      s_status: row.s_status == 1 ? 2 : 1,
    };
    ScenicApi.setSestatus(data).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("园区状态修改成功！");
        // 重新获取园区列表数据
        scenicTable.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "园区状态修改失败！";
        ElMessage.error(msg);
      }
    });
  };
  /** 点击图册处理 */
  const albumClick = (row) => {
    currentRow.value = row;
    getScenicImgs();
  };
  /** 表格 编辑 */
  const scenicEdit = (row) => {
    isAreaEdit.value = true;
    scenicArea.value.show();
    getScenicInfo({ s_id: row.s_id }, "edit");
  };
  /** 表格 游玩指引 */
  const showGuide = (row) => {
    guideDialog.value.show();

    getScenicInfo({ s_id: row.s_id }, "guide");
  };
  /** 获取园区详情数据 */
  const getScenicInfo = (data, type) => {
    ScenicApi.semanageInfo(data).then((res) => {
      if (res.Code === 200) {
        currentRow.value = res.Data;
        store.dispatch("getQiniuData");
        if (type === "edit") {
          let times = res.Data.s_business_hours.split("-");
          let st = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[0]);
          let et = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[1]);
          areaForm.name = res.Data.s_name;
          areaForm.transitTime = [st, et];
          areaForm.addr = res.Data.s_place;
          areaForm.coord = res.Data.s_coordinate;
          areaForm.intro = res.Data.s_introduce;
          areaForm.s_hot_line = res.Data.s_hot_line;
          areaForm.images = res.Data.images || [];
          scenicEditorInit();
        } else {
          guideEditorImage.value = res.Data.image || [];
          guideEditorInit();
          guideEditor.value.txt.html(res.Data.s_play_guide);
        }
      } else {
        let msg = res.Message ? res.Message : "获取园区详情数据失败！";
        ElMessage.error(msg);
      }
    });
  };

  /** 新增/编辑 园区弹框 */
  const scenicArea = ref(null);
  /** 园区编辑/新增操作 */
  const isAreaEdit = ref(false);
  /** 表单对象 */
  const areaFormObj = ref(null);
  /** 表单数据对象 */
  const areaForm = reactive({
    pname: "", // 项目
    name: "", // 园区名称
    transitTime: "", // 通行时间
    s_hot_line: "",  // 联系电话
    addr: "", // 园区地址
    coord: "", // 园区坐标
    intro: "", // 园区简介
    images: [],
  });
  /** 表单规则对象 */
  const areaRules = reactive({
    pname: [
      {
        required: false,
        message: "请选择项目",
        trigger: "change",
      },
    ],
    name: [
      {
        required: true,
        message: "请输入园区名称",
        trigger: "blur",
      },
    ],
    transitTime: [
      {
        required: true,
        message: "请选择通行时间",
        trigger: "blur",
      },
    ],
    s_hot_line: [
      {
        required: true,
        message: "请输入电话号码",
        trigger: "blur",
      },
    ],
    addr: [
      {
        required: true,
        message: "请输入园区地址",
        trigger: "blur",
      },
    ],
    coord: [
      {
        required: true,
        message: "请输入园区坐标",
        trigger: "blur",
      },
    ],
    intro: [
      {
        required: true,
        message: "请输入园区简介",
        trigger: "change",
      },
    ],
  });
  /** 园区 富文本容器 */
  const scenicEditorElem = ref(null);
  /** 园区 富文本实例对象 */
  const scenicEditor = ref(null);
  /** 园区 富文本初始化 */
  const scenicEditorInit = () => {
    scenicEditor.value = new Editor(scenicEditorElem.value);
    scenicEditor.value.config.colors = [
      "#000000",
      "#eeece0",
      "#1c487f",
      "#4d80bf",
      "#0072f6",
      "#1a1a1a",
      "#FFB900",
      "#EB5774",
      "#f2f2f2",
      "#666666",
      "#999999",
      "#cccccc",
    ];
    // 配置 onchange 回调函数
    scenicEditor.value.config.onchange = (newHtml) => {
      areaForm.intro = newHtml;
      areaFormObj.value.validateField("intro");
    };
    // 自定义菜单配置
    scenicEditor.value.config.menus = [
      "head",
      "bold",
      "fontSize",
      "fontName",
      "italic",
      "underline",
      "strikeThrough",
      "indent",
      "lineHeight",
      "foreColor",
      "justify",
      "quote",
      "splitLine",
      "image",
      "undo",
      "redo",
    ];
    scenicEditor.value.config.uploadImgAccept = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
    ];
    scenicEditor.value.config.qiniu = true;
    scenicEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
    const upToken = qiniuData.value.token;
    const domain = qiniuData.value.http_domain;
    scenicEditor.value.config.customUploadImg = (file) => {
      if (file.length) {
        file.forEach((el) => {
          const myDate = new Date();
          const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
          const timeData = Date.parse(new Date()); //当前时间时时间戳
          const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
          const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
          const filename = el.name;
          const index = filename.lastIndexOf(".");
          const suffix = filename.substr(index + 1);
          const key = `scenic-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
          const config = {
            // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            cdnUphost: domain,
          };
          const putExtra = {
            fname: "", //文件原文件名
            params: {}, //用来放置自定义变量
            mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
          };
          const observable = Qiniu.upload(el, key, upToken, putExtra, config);
          observable.subscribe({
            error: () => { },
            complete: (res) => {
              const sourceLink = domain + res.key;
              scenicEditor.value.cmd.do(
                "insertHtml",
                '<img src="' + sourceLink + '" style="max-width:100%;"/>'
              );
              areaForm.images.push({
                filename: filename,
                qiniu_key: res.key,
                size: el.size,
              });
            },
          });
        });
      }
    };

    scenicEditor.value.config.height = 250;
    // 创建富文本实例
    scenicEditor.value.create();
    scenicEditor.value.txt.html(areaForm.intro);
  };
  /** 园区编辑 确认 */
  const scenicEditConfirm = () => {
    areaFormObj.value.validate((valid) => {
      if (valid) {
        scenicArea.value.isLoading = true;
        let stime = dayjs(areaForm.transitTime[0]).format("HH:mm:ss");
        let etime = dayjs(areaForm.transitTime[1]).format("HH:mm:ss");
        let data = {
          s_id: currentRow.value.s_id,
          s_name: areaForm.name,
          s_business_hours: stime + "-" + etime,
          s_place: areaForm.addr,
          s_coordinate: areaForm.coord,
          s_introduce: areaForm.intro,
          images: areaForm.images,
          s_hot_line: areaForm.s_hot_line,
        };
        ScenicApi.semanageEdit(data).then((res) => {
          scenicArea.value.isLoading = false;
          if (res.Code === 200) {
            ElMessage.success("园区编辑成功！");
            scenicArea.value.close();
            // 重新获取园区数据
            scenicTable.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : "园区编辑失败！";
            ElMessage.error(msg);
          }
        });
      }
    });
  };

  /** 园区入口 弹框 */
  const areaEntrance = ref(null);
  /** 园区入口表格对象 */
  const areaTable = ref(null);
  /** 园区入口 表格配置 */
  const areaTableColumns = ref([
    {
      prop: "se_id",
      label: "入口ID",
      minWidth: 50,
      color: "--text-color",
    },
    {
      prop: "relation_name",
      label: "所属园区",
      minWidth: 180,
      color: "--text-third-color",
      showTooltip: true,
    },
    {
      prop: "se_name",
      label: "入口名称",
      minWidth: 120,
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "se_access_stime",
      label: "通行开始时间",
      minWidth: 100,
      color: "--text-third-color",
    },
    {
      prop: "se_access_etime",
      label: "通行结束时间",
      minWidth: 100,
      color: "--text-third-color",
    },
    {
      type: "switch",
      prop: "se_status",
      label: "入口状态",
      minWidth: 70,
      token: "n_rPsoy3HGT7RxOvZ5zFaUJCIB2isy",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "编辑",
          action: "edit",
          className: "theme-font-btn",
          token: "n_jlKqxpRZPhpu1YuYO4jBhNTDqNgd",
        },
      ],
    },
  ]);
  /** 当前编辑 行数据 */
  const currentAent = ref(null);
  /** 园区入口 编辑处理 */
  const entranceEdit = (row) => {
    currentAent.value = row;
    aentForm.scenic = row.relation_name;
    aentForm.name = row.se_name;
    let st = dayjs(dayjs().format("YYYY-MM-DD") + " " + row.se_access_stime);
    let et = dayjs(dayjs().format("YYYY-MM-DD") + " " + row.se_access_etime);
    aentForm.transitTime = [st, et];
    aentEdit.value.show();
  };
  /** 园区入口 状态改变 */
  const entranceStatusChange = (row) => {
    let data = {
      se_id: row.se_id,
      se_status: row.se_status == 1 ? 2 : 1,
    };
    ScenicApi.setEntranceStatus(data).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("入口状态修改成功！");
        // 重新获取园区入口数据
        areaTable.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "入口状态修改失败！";
        ElMessage.error(msg);
      }
    });
  };

  /** 园区入口编辑 弹框 */
  const aentEdit = ref(null);
  /** 表单对象 */
  const aentFormObj = ref(null);
  /** 表单数据对象 */
  const aentForm = reactive({
    project: "", // 项目
    scenic: "", // 园区
    name: "", // 入口名称
    transitTime: [], // 通行时间
  });
  /** 表单规则对象 */
  const aentRules = reactive({
    project: [
      {
        required: false,
        message: "请选择项目",
        trigger: "change",
      },
    ],
    scenic: [
      {
        required: false,
        message: "请选择园区",
        trigger: "change",
      },
    ],
    name: [
      {
        required: true,
        message: "请输入入口名称",
        trigger: "blur",
      },
    ],
    transitTime: [
      {
        required: true,
        message: "请选择通行时间",
        trigger: "change",
      },
    ],
  });
  /** 园区入口编辑确认 */
  const aentEditConfirm = () => {
    aentFormObj.value.validate((valid) => {
      if (valid) {
        aentEdit.value.isLoading = true;
        let data = {
          se_id: currentAent.value.se_id,
          se_name: aentForm.name,
          se_access_stime: dayjs(aentForm.transitTime[0]).format("HH:mm:ss"),
          se_access_etime: dayjs(aentForm.transitTime[1]).format("HH:mm:ss"),
        };
        ScenicApi.sentranceEdit(data).then((res) => {
          aentEdit.value.isLoading = false;
          if (res.Code === 200) {
            ElMessage.success("园区入口编辑成功！");
            aentEdit.value.close();
            // 重新获取园区入口数据
            areaTable.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : "园区入口编辑失败！";
            ElMessage.error(msg);
          }
        });
      }
    });
  };

  /** 景点入口 弹框 */
  const spotEntrance = ref(null);
  /** 包含景点表格对象 */
  const spotTable = ref(null);
  /** 景点入口 表格配置 */
  const spotTableColumns = ref([
    {
      prop: "sp_name",
      label: "景点名称",
      minWidth: 200,
      color: "--text-color",
      showTooltip: true,
    },
    {
      prop: "sp_business_hours",
      label: "通行时间",
      minWidth: 120,
      color: "--text-third-color",
    },
    {
      type: "switch",
      prop: "sp_status",
      label: "景点状态",
      minWidth: 60,
      token: "n_kKdBSO3OovqrYFs5w32DiuLPknWk",
    },
    {
      prop: "u_name",
      label: "创建人",
      minWidth: 60,
      color: "--text-third-color",
    },
    {
      prop: "ctime",
      label: "创建时间",
      color: "--text-third-color",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 200,
      bottons: [
        {
          name: "编辑",
          action: "edit",
          className: "theme-font-btn",
          token: "n_FxBKo5rF3BltbcItZXRVuls8v3DE",
        },
        {
          name: "退款管理员",
          action: "check",
          token: "n_wVix2ZpWLnJZ8pIFx7w29lqudSdq",
          className: "black-font-btn",
        },
      ],
    },
  ]);
  /** 当前编辑景点 行数据 */
  const currentSpot = ref(null);
  /** 景点编辑 */
  const spotEdit = (row) => {
    isSpotEdit.value = true;
    scenicSpot.value.show();
    getSpotInfo({ sp_id: row.sp_id });
  };
  /** 景点状态修改 */
  const spotStatusChange = (row) => {
    let data = {
      sp_id: row.sp_id,
      sp_status: row.sp_status == 1 ? 2 : 1,
    };
    ScenicApi.setSpotStatus(data).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("景点状态修改成功！");
        // 重新获取园区入口数据
        spotTable.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "景点状态修改失败！";
        ElMessage.error(msg);
      }
    });
  };
  /** 获取景点详情数据 */
  const getSpotInfo = (data) => {
    ScenicApi.spotEntranceInfo(data).then((res) => {
      if (res.Code === 200) {
        currentSpot.value = res.Data;
        spotForm.name = res.Data.sp_name;
        spotForm.introduce = res.Data.sp_introduce;
        spotForm.images = res.Data.images || [];
        let times = res.Data.sp_business_hours.split("-");
        let st = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[0]);
        let et = dayjs(dayjs().format("YYYY-MM-DD") + " " + times[1]);
        spotForm.transitTime = [st, et];
        store.dispatch("getQiniuData");
        spotEditorInit();
      } else {
        let msg = res.Message ? res.Message : "获取景点详情数据失败！";
        ElMessage.error(msg);
      }
    });
  };

  /** 新增/编辑 景点弹框 */
  const scenicSpot = ref(null);
  /** 景点编辑/新增操作 */
  const isSpotEdit = ref(false);
  /** 表单对象 */
  const spotFormObj = ref(null);
  /** 表单数据对象 */
  const spotForm = reactive({
    name: "", // 景点名称
    transitTime: "", // 营业时间
    introduce: "", // 景点简介
    images: [],
  });
  /** 表单规则对象 */
  const spotRules = reactive({
    name: [
      {
        required: true,
        message: "请输入景点名称",
        trigger: "blur",
      },
    ],
    transitTime: [
      {
        required: true,
        message: "请选择营业时间",
        trigger: "change",
      },
    ],
    introduce: [
      {
        required: true,
        message: "请输入景点简介",
        trigger: "change",
      },
    ],
  });
  /** 景点 富文本容器 */
  const spotEditorElem = ref(null);
  /** 景点 富文本实例对象 */
  const spotEditor = ref(null);
  /** 景点 富文本初始化 */
  const spotEditorInit = () => {
    spotEditor.value = new Editor(spotEditorElem.value);
    spotEditor.value.config.colors = [
      "#000000",
      "#eeece0",
      "#1c487f",
      "#4d80bf",
      "#0072f6",
      "#1a1a1a",
      "#FFB900",
      "#EB5774",
      "#f2f2f2",
      "#666666",
      "#999999",
      "#cccccc",
    ];
    // 配置 onchange 回调函数
    spotEditor.value.config.onchange = (newHtml) => {
      spotForm.introduce = newHtml;
      spotFormObj.value.validateField("introduce");
    };
    // 自定义菜单配置
    spotEditor.value.config.menus = [
      "head",
      "bold",
      "fontSize",
      "fontName",
      "italic",
      "underline",
      "strikeThrough",
      "indent",
      "lineHeight",
      "foreColor",
      "justify",
      "quote",
      "splitLine",
      "image",
      "undo",
      "redo",
    ];
    spotEditor.value.config.uploadImgAccept = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
    ];
    spotEditor.value.config.qiniu = true;
    spotEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
    const upToken = qiniuData.value.token;
    const domain = qiniuData.value.http_domain;
    spotEditor.value.config.customUploadImg = (file) => {
      if (file.length) {
        file.forEach((el) => {
          const myDate = new Date();
          const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
          const timeData = Date.parse(new Date()); //当前时间时时间戳
          const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
          const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
          const filename = el.name;
          const index = filename.lastIndexOf(".");
          const suffix = filename.substr(index + 1);
          const key = `spot-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
          const config = {
            // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            cdnUphost: domain,
          };
          const putExtra = {
            fname: "", //文件原文件名
            params: {}, //用来放置自定义变量
            mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
          };
          const observable = Qiniu.upload(el, key, upToken, putExtra, config);
          observable.subscribe({
            error: () => { },
            complete: (res) => {
              const sourceLink = domain + res.key;
              spotEditor.value.cmd.do(
                "insertHtml",
                '<img src="' + sourceLink + '" style="max-width:100%;"/>'
              );
              spotForm.images.push({
                filename: filename,
                qiniu_key: res.key,
                size: el.size,
              });
            },
          });
        });
      }
    };

    spotEditor.value.config.height = 250;
    // 创建富文本实例
    spotEditor.value.create();
    spotEditor.value.txt.html(spotForm.introduce);
  };
  /** 景点编辑 确认 */
  const spotEditConfirm = () => {
    spotFormObj.value.validate((valid) => {
      if (valid) {
        scenicSpot.value.isLoading = true;
        let stime = dayjs(spotForm.transitTime[0]).format("HH:mm:ss");
        let etime = dayjs(spotForm.transitTime[1]).format("HH:mm:ss");
        let data = {
          sp_id: currentSpot.value.sp_id,
          sp_name: spotForm.name,
          sp_business_hours: stime + "-" + etime,
          sp_introduce: spotForm.introduce,
          images: spotForm.images,
        };
        ScenicApi.spotEntranceEdit(data).then((res) => {
          scenicSpot.value.isLoading = false;
          if (res.Code === 200) {
            ElMessage.success("景点编辑成功！");
            scenicSpot.value.close();
            // 重新获取园区入口数据
            spotTable.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : "景点编辑失败！";
            ElMessage.error(msg);
          }
        });
      }
    });
  };

  /** 游玩指引弹框 */
  const guideDialog = ref(null);
  /** 景点 富文本容器 */
  const guideEditorElem = ref(null);
  const guideEditorImage = ref([]);
  /** 景点 富文本实例对象 */
  const guideEditor = ref(null);
  /** 景点 富文本初始化 */
  const guideEditorInit = () => {
    guideEditor.value = new Editor(guideEditorElem.value);
    guideEditor.value.config.colors = [
      "#000000",
      "#eeece0",
      "#1c487f",
      "#4d80bf",
      "#0072f6",
      "#1a1a1a",
      "#FFB900",
      "#EB5774",
      "#f2f2f2",
      "#666666",
      "#999999",
      "#cccccc",
    ];
    // 自定义菜单配置
    guideEditor.value.config.menus = [
      "head",
      "bold",
      "fontSize",
      "fontName",
      "italic",
      "underline",
      "strikeThrough",
      "indent",
      "lineHeight",
      "foreColor",
      "justify",
      "quote",
      "splitLine",
      "image",
      "undo",
      "redo",
    ];
    // 限制上传图片格式
    guideEditor.value.config.uploadImgAccept = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
    ];
    guideEditor.value.config.qiniu = true;
    guideEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
    const upToken = qiniuData.value.token;
    const domain = qiniuData.value.http_domain;
    guideEditor.value.config.customUploadImg = (file) => {
      if (file.length) {
        file.forEach((el) => {
          const myDate = new Date();
          const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
          const timeData = Date.parse(new Date()); //当前时间时时间戳
          const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
          const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
          const filename = el.name;
          const index = filename.lastIndexOf(".");
          const suffix = filename.substr(index + 1);
          const key = `play-guide-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
          const config = {
            // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            cdnUphost: domain,
          };
          const putExtra = {
            fname: "", //文件原文件名
            params: {}, //用来放置自定义变量
            mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
          };
          const observable = Qiniu.upload(el, key, upToken, putExtra, config);
          observable.subscribe({
            error: () => { },
            complete: (res) => {
              const sourceLink = domain + res.key;
              guideEditor.value.cmd.do(
                "insertHtml",
                '<img src="' + sourceLink + '" style="max-width:100%;"/>'
              );
              guideEditorImage.value.push({
                filename: filename,
                qiniu_key: res.key,
                size: el.size,
              });
            },
          });
        });
      }
    };

    guideEditor.value.config.height = 400;
    // 创建富文本实例
    guideEditor.value.create();
  };
  /** 游玩指引编辑确认 */
  const guideConfirm = () => {
    guideDialog.value.isLoading = true;
    let data = {
      s_id: currentRow.value.s_id,
      s_play_guide: guideEditor.value.txt.html(),
      image: guideEditorImage.value,
    };
    ScenicApi.playGuide(data).then((res) => {
      guideDialog.value.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success("游玩指引编辑成功！");

        guideDialog.value.close();
        // 重新获取园区数据
        scenicTable.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "游玩指引编辑失败！";
        ElMessage.error(msg);
      }
    });
  };
  const photoDialog = ref(null);
  /** 多文件上传列表 */
  const mulFileObj = ref({
    sid: "",
    sft_id: "",
    file_type: "",
    files: [],
    cover_files: [],
  });
  /** 是否正在上传图片 */

  const fullLoading = ref(false);
  // 图册

  const photoAlbum = ref([]);
  const coverPhoto = ref([]);
  /** 获取园区图册数据 */
  const getScenicImgs = () => {
    store.dispatch("getQiniuData");
    coverPhoto.value = [];
    photoAlbum.value = [];
    ScenicApi.getPhotos({ s_id: currentRow.value.s_id }).then((res) => {
      if (res.Code === 200) {
        res.Data &&
          res.Data.length &&
          res.Data.forEach((el) => {
            if (el.sf_cover == 2) {
              coverPhoto.value.push(el);
            } else {
              photoAlbum.value.push(el);
            }
          });
        photoDialog.value.show();
      } else {
        let msg = res.Message ? res.Message : "获取园区图册数据失败！";
        ElMessage.error(msg);
      }
    });
  };
  /** 文件上传 */
  const uploadFile = (data, isCover) => {
    if (authData.value.indexOf("n_UPdRoa8HQMt9N1ck4cT14ETDAn2O") != -1) {
      fullLoading.value = true;
      mulFileObj.value.sid = currentRow.value.s_id;
      mulFileObj.value.sft_id = 9;
      mulFileObj.value.file_type = 1;
      if (isCover) {
        mulFileObj.value.cover_files.push(data.key);
      } else {
        mulFileObj.value.files.push(data.key);
      }
      // 去抖上传
      debounce(uploadSubmit, 300);
    } else {
      ElMessage.warning("您没有操作的权限！");
    }
  };
  /** 定时器 id */
  const timer = ref(null);
  /** 去抖函数 */
  const debounce = (fn, delay) => {
    if (timer.value) {
      clearTimeout(timer.value);
    }
    timer.value = setTimeout(fn, delay);
  };
  /** 上传提交 */
  const uploadSubmit = () => {
    ScenicApi.insertPhoto(mulFileObj.value).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("上传成功！");
        // 获取一次图册数据
        getScenicImgs();
        scenicTable.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "上传失败！";
        ElMessage.error(msg);
      }
      fullLoading.value = false;
    });
    mulFileObj.value = {
      hrt_id: "",
      sft_id: "",
      file_type: "",
      files: [],
      cover_files: [],
    };
  };
  /** 文件删除 */
  const deleteFile = (data) => {
    if (authData.value.indexOf("n_TNJTI12SuqoTONemKHwVsmsHtM7f") != -1) {
      ScenicApi.delPhoto({ sf_id: data.sf_id }).then((res) => {
        if (res.Code === 200) {
          ElMessage.success("图片删除成功！");
          // 获取一次图册数据
          getScenicImgs();
          scenicTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : "图片删除失败！";
          ElMessage.error(msg);
        }
      });
    } else {
      ElMessage.warning("您没有删除权限！");
    }
  };
  const checkDialog = ref(null);
  function openCheckDialog(row) {
    checkDialog.value.openDialog("scenic", row.p_name, row.s_id, {
      delete: "n_6Nw9j80n2hloXATEfzcxDyKeYOVG",
      add: "n_XoY5z8Z79tmWjIOxTPDgBVeEQSc6",
    });
  }
  const spotCheckDialog = ref(null);
  function openSpotCheckDialog(row) {
    spotCheckDialog.value.openDialog("scenic_spot", row.sp_name, row.sp_id, {
      delete: "n_XHkc1hQI4VCP86Wgyfz5L7n9ZOsJ",
      add: "n_I3NpTse8om7GUEBbV6FzLrHZSQJu",
    });
  }
  onMounted(() => {
    scenicTable.value.tableLoad();
  });
</script>

<style lang="scss">
  .secnf {
    font-family: "Source Han Sans CN";

    .content {
      padding: 20px;
    }

    .scenic-area,
    .scenic-spot {
      .el-dialog {
        min-width: 540px;

        .el-dialog__body {
          padding: 0 15px 40px;

          .area-form,
          .spot-form {
            display: flex;
            flex-wrap: wrap;

            .el-form-item {
              width: 33.3%;
              padding: 20px 15px 0;
              margin-bottom: 0;

              .el-form-item__content {
                .el-date-editor {
                  width: 100%;

                  .el-range-input {
                    background-color: transparent;
                  }
                }
              }
            }

            .el-form-item.block {
              width: 100%;
            }

            .el-divider {
              margin: 30px 0 0 15px;
              width: calc(100% - 30px);
            }
          }
        }
      }
    }

    .entrance {
      .el-dialog {
        min-width: 640px;

        .el-dialog__body {
          .name {
            font-size: 16px;
            font-weight: 700;
            color: var(--text-color);
            margin-bottom: 10px;
          }
        }
      }
    }

    .aent-edit {
      .el-dialog {
        min-width: 540px;

        .el-dialog__body {
          padding: 5px 15px 80px;

          .aent-form {
            display: flex;
            flex-wrap: wrap;

            .el-form-item {
              width: 50%;
              padding: 25px 15px 0;
              margin-bottom: 0;

              .el-form-item__content {
                .el-date-editor {
                  width: 100%;

                  .el-range-input {
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }

    .photo-dialog {
      .name {
        color: var(--text-color);
        margin-bottom: 4px;
      }

      .img-upload {
        margin-bottom: 20px;
      }
    }
  }
</style>